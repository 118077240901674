<template>
    <div class="about">
        <div class="banner color-white">
            <div class="content">
                <div class="title font-bold">
                    我們能做什麼?
                    <div class="red-line-l"></div>
                </div>
                <div class="text">
                    本工會協助言語、音樂、效果及其他有關聲音之配錄工作人員處理勞健保事務，<br
                        class="is-desktop"
                    />提供配音專業課程，培育專業配音人才，媒合更多配音工作機會。
                </div>
            </div>
        </div>
        <div class="info color-black">
            <div class="content">
                <div class="left">
                    <div class="description">
                        <div class="title font-bold">
                            關於臺北市配音人員職業工會
                            <div class="red-line"></div>
                        </div>
                        <div class="detail">
                            <div
                                class="item"
                                v-for="item in descriptions"
                                :key="item.label"
                            >
                                <div class="label font-bold">
                                    {{ item.label }}
                                </div>
                                <div class="text">{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="service">
                        <div class="title font-bold">
                            服務項目
                            <div class="red-line"></div>
                        </div>
                        <div class="detail">
                            <div
                                class="item"
                                v-for="item in services"
                                :key="item.label"
                            >
                                <div class="label font-bold">
                                    {{ item.label }}
                                </div>
                                <div class="text">{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img
                        class="certificate"
                        src="../assets/about/certificate.jpg"
                        alt=""
                    />
                    <div class="img-name">立案證書 北市⼯字第 359 號</div>
                </div>
            </div>
        </div>
        <div class="org">
            <div class="content">
                <div class="title font-bold">
                    工會組織圖
                    <div class="red-line"></div>
                </div>
                <div class="img-box">
                    <img
                        class="is-desktop"
                        src="../assets/about/org.png"
                        alt=""
                    />
                    <img
                        class="is-mobile"
                        src="../assets/about/org-mobile.png"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            descriptions: [
                {
                    label: '成立日期',
                    text: '⺠國81年12⽉12⽇成立',
                },
                {
                    label: '工會宗旨',
                    text: '本會以團結配音從業人員，保障會員權益，增進會員知能，協調勞資合作，謀求會員福利，改善會員生活，並協助政府推行政令為宗旨。',
                },
            ],
            services: [
                {
                    label: '全民健保',
                    text: '配合中央健康保險局實施全⺠健保，同時為會員眷屬辦理依附被保險⼈加入全⺠健康保險，以享有健保照護',
                },
                {
                    label: '辦理勞⼯保險',
                    text: '協助會員辦理入會加保勞⼯保險⼿續，並協助會員申請勞⼯保險各項給付申領。',
                },
            ],
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.is-desktop {
    display: block;
}

.is-mobile {
    display: none;
}
.content {
    width: 80vw;
    max-width: 1140px;
}
.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/about/banner.png');
    .title {
        font-size: 40px;
        margin-bottom: 40px;
    }

    .text {
        font-size: 18px;
        line-height: 30px;
    }
}

.info {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    background-color: #BDA996;
    background-image: url('../assets/about/info-bg.png');
    background-repeat: no-repeat;
    background-size: cover;

    .content {
        display: flex;
        align-items: center;
        .description {
            margin-bottom: 40px;
        }
        .certificate {
            width: 305px;
        }
        .left {
            margin-right: 10%;
        }

        .right {
            .img-name {
                font-size: 16px;
                line-height: 30px;
                text-align: center;
                margin-top: 10px;
            }
        }

        .detail {
            font-size: 16px;
            line-height: 30px;
            margin-top: 30px;
            .item {
                display: flex;
                margin-bottom: 12px;
                .label {
                    width: 140px;
                    flex-shrink: 0;
                }
            }
        }
    }
    .title {
        font-size: 20px;
    }
}

.org {
    display: flex;
    justify-content: center;
    background-image: url('../assets/about/org-bg.png');
    background-size: cover;
    background-position: 50% 50%;
    .content {
        padding: 60px 0 120px 0;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 20px;
        }

        .img-box {
            display: flex;
            justify-content: center;
            img {
                width: 840px;
                margin-top: 75px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .is-desktop {
        display: none;
    }

    .is-mobile {
        display: block;
    }
    .content {
        width: 85vw;
    }

    .banner {
        background-image: url('../assets/about/banner-mobile.jpg');
        .title {
            font-size: 28px;
            margin-bottom: 75px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .text {
            font-size: 16px;
            line-height: 25px;
            text-align: justify;
        }
    }

    .info {
        background-image: url('../assets/about/info-bg-mobile.png');
        padding: 80px 0;

        .content {
            flex-direction: column;
            .description {
                margin-bottom: 60px;
            }

            .left {
                margin: 0;
            }
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 24px;
            }

            .detail {
                .item {
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    margin-bottom: 40px;
                    .label {
                        width: 100%;
                    }
                }
            }
        }
    }

    .org {
        background-image: url('../assets/about/org-bg-mobile.png');
        .content {
            padding: 80px 0;
            .title {
                font-size: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .img-box {
                img {
                    width: 90vw;
                    margin-top: 50px;
                }
            }
        }
    }
}
</style>
